document.addEventListener(
  'DOMContentLoaded',
  () => {
    const body = document.getElementsByTagName('body')[0]
    const bodyPos = body.style.position
    const bodyWidth = body.style.width
    const isSevenroomsHost = window.location.host.indexOf('sevenrooms') >= 0
    let srButton = document.getElementById('sr-events-root') || document.getElementById('sr-root')

    if (srButton) {
      // eslint-disable-next-line no-inner-declarations
      function addHeadLink(rel, ttype, href) {
        const link = document.createElement('link')
        link.rel = rel
        link.type = ttype
        link.href = href
        document.head.appendChild(link)
      }
      // load sr icon font
      const logoCssHref = isSevenroomsHost ? '/css/logo.css' : 'https://www.sevenrooms.com/css/logo.css'
      addHeadLink('stylesheet', 'text/css', logoCssHref)
      const fontsCssHref = isSevenroomsHost ? '/css/embed-fonts.css' : 'https://www.sevenrooms.com/css/embed-fonts.css'
      addHeadLink('stylesheet', 'text/css', fontsCssHref)

      const text = srButton.textContent
      srButton.textContent = ''
      const classes = srButton.className.split(' ')
      if (classes[0].slice(0, 2) === 'sr') {
        const color = classes[2].slice(3)
        const iTag = document.createElement('i')
        iTag.className += 'sr-fa sr-fa-logo'
        const buttonText = document.createElement('span')
        buttonText.textContent = text
        if (classes[0] === 'sr-sm' && classes[1] === 'sr-dark') {
          srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: ${color}; color: white; font-weight: 100; padding: 5px 0px 7px 6px; width: 127px; font-size: 12px; height: 26px;`
          iTag.style.cssText = 'display: block; float: left; font-size: 13px; margin: 1px 0 0 6px;'
          buttonText.style.cssText =
            'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 87px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
        } else if (classes[0] === 'sr-sm' && classes[1] === 'sr-light') {
          srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: white; color: ${color}; border: 1px solid ${color}; font-weight: 400; padding: 5px 0px 7px 6px; width: 127px; font-size: 12px; height: 26px;`
          iTag.style.cssText = 'display: block; float: left; font-size: 13px; margin: 1px 0 0 6px;'
          buttonText.style.cssText =
            'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 87px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
        } else if (classes[0] === 'sr-md' && classes[1] === 'sr-dark') {
          srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: ${color}; color: white; font-weight: 100; padding: 9px 0px 12px 10px; width: 152px; font-size: 14px; height: 35px;`
          iTag.style.cssText = 'display: block; float: left; font-size: 17px; margin: 0 0 0 4px;'
          buttonText.style.cssText =
            'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 100px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
        } else if (classes[0] === 'sr-md' && classes[1] === 'sr-light') {
          srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: white; color: ${color}; border: 1px solid ${color}; font-weight: 400; padding: 9px 0px 12px 10px; width: 152px; font-size: 14px; height: 35px;`
          iTag.style.cssText = 'display: block; float: left; font-size: 17px; margin: 0 0 0 4px;'
          buttonText.style.cssText =
            'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 100px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
        } else if (classes[0] === 'sr-lg' && classes[1] === 'sr-dark') {
          srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: ${color}; color: white; font-weight: 100; padding: 14px 0px 17px 10px; width: 178px; font-size: 18px; height: 45px;`
          iTag.style.cssText = 'display: block; float: left; font-size: 21px; margin: -3px 0 0 2px;'
          buttonText.style.cssText =
            'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 130px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
        } else if (classes[0] === 'sr-lg' && classes[1] === 'sr-light') {
          srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: white; color: ${color}; border: 1px solid ${color}; font-weight: 400; padding: 14px 0px 17px 10px; width: 178px; font-size: 18px; height: 45px;`
          iTag.style.cssText = 'display: block; float: left; font-size: 21px; margin: -3px 0 0 2px;'
          buttonText.style.cssText =
            'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 130px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
        }
        document.fonts.ready.then(() => {
          srButton.appendChild(iTag)
          srButton.appendChild(buttonText)
        })
      }
    } else {
      srButton = document.getElementById('sr-preview')
    }

    const scene = document.createElement('div')

    function exit() {
      body.removeChild(scene)
      body.style.position = bodyPos
      body.style.width = bodyWidth
    }

    scene.id = 'sr-scene'
    scene.style.cssText = 'top:0px;left:0px;height:100%;width:100%;position:absolute;;z-index:9999998;'
    scene.addEventListener('click', exit)

    const cover = document.createElement('div')
    cover.id = 'sr-cover'
    cover.style.cssText = 'background:rgba(26,25,25,0.901961);top:0px;height:120%;left:0px;width:100%;opacity:1;position:fixed;'
    scene.appendChild(cover)

    const container = document.createElement('div')
    container.id = 'sr-container'
    container.style.cssText =
      'box-shadow:rgba(0,0,0,0.298039) 0px 0px 3px;' +
      'height:88vh;margin:6vh auto 0;width:100%;' +
      'max-width:700px;min-width:300px;opacity:1;position:relative;'
    scene.appendChild(container)
    container.addEventListener('click', event => {
      event.stopPropagation()
    })

    const x = document.createElement('div')
    x.id = 'exit-x'
    x.appendChild(document.createTextNode('×'))
    x.style.cssText =
      'position:absolute;color:rgb(153,153,153);font-size:5.2vh;' +
      'font-weight:normal;cursor:pointer;font-family:Roboto;right:1.5vw;' +
      'top:-6vh;'
    x.addEventListener('click', exit)
    container.appendChild(x)

    const iframe = document.createElement('iframe')
    let iframeSrc
    let footerSrc
    if (isSevenroomsHost) {
      iframeSrc = `/events/${window.SEVENROOMS_VENUE_ID}`
      footerSrc = '/images/widget/sevenrooms_footer.png'
    } else {
      iframeSrc = `https://www.sevenrooms.com/events/${window.SEVENROOMS_VENUE_ID}`
      footerSrc = 'https://www.sevenrooms.com/images/widget/sevenrooms_footer.png'
    }
    iframe.src = iframeSrc
    iframe.style.cssText = 'border:none;height:100%;width:100%;background-color:transparent;'
    container.appendChild(iframe)

    const footer = document.createElement('img')
    footer.src = footerSrc
    footer.style.cssText = 'position:relative;bottom:-2vh;height:18px;display:block;margin:0 auto'
    scene.appendChild(footer)

    srButton.onclick = function onclick() {
      iframe.src = `${iframe.src.split('?')[0]}?hide-calendar=${window.HIDE_CALENDAR}`
      body.appendChild(scene)
      body.style.position = 'fixed'
      body.style.width = '100%'
    }
  },
  false
)
